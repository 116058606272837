<template>
  <div></div>
</template>

<script>
export default {
  created() {
    // push login page instantly
    this.$router.push('/login')
  }
}
</script>

<style lang="scss" scoped></style>
